import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import './MobileMenu.css';

const MobileMenu = ({ onCategoryClick, availableCountries }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useAppContext();

  const handleCountryClick = (country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
    setIsOpen(false);
  };

  const handleCategoryClick = () => {
    onCategoryClick();
    setIsOpen(false);
  };

  const getButtonClass = (countryCode) => {
    if (state.selectedCountry === countryCode) return 'active';
    return '';
  };

  return (
    <div className="mobile-menu">
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className="hamburger-button"
      >
        {isOpen ? <X size={24} /> : <Menu size={24}  strokeWidth={1.5}/>}
      </button>

      {isOpen && (
        <div className="menu-content">
          <button onClick={handleCategoryClick} className="category-button-mobile">Select Category</button>
          
          <div className="country-list">
            {Object.entries(availableCountries).map(([code, country]) => (
              <button
                key={code}
                onClick={() => handleCountryClick(code)}
                className={`country-button ${getButtonClass(code)}`}
              >
                {country.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;