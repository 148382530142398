  import React, { useState, useEffect } from 'react';
  import { X } from 'lucide-react';
  import { useAppContext } from './AppContext.js';
  import './CategoryFilterModal.css'

  const subCategories = {
    "news": ["business", "elections", "entertainment", "finance", "financial institution", "general", "health",
      "legal", "science", "sports", "technology", "emergency", "accident", "flight", "vessel"],
    "conflict": ["air defence", "accident/death", "airforce", "military", "missile", "navy", "navy ship",
      "terror", "warzone", "explosion"]
  };
  const category=['news','conflict'];

  const categoryIcons = [
    { id: 'all', label: 'All' },
    { id: 'accident', label: 'Accident', icon: '/PNG/PNG/accident.png' },
    { id: 'accident/death', label: 'Accident/Death', icon: '/PNG/PNG/accident_death.png' },
    { id: 'air defence', label: 'Air Defence', icon: '/PNG/PNG/air defence.png' },
    { id: 'airforce', label: 'Air Force', icon: '/PNG/PNG/air force.png' },
    { id: 'business', label: 'Business', icon: '/PNG/PNG/business.png' },
    { id: 'elections', label: 'Elections', icon: '/PNG/PNG/elections.png' },
    { id: 'emergency', label: 'Emergency', icon: '/PNG/PNG/emergency.png'},
    { id: 'entertainment', label: 'Entertainment', icon: '/PNG/PNG/entertainment.png' },
    { id: 'explosion', label: 'Explosion', icon: '/PNG/PNG/explosion.png' },
    { id: 'finance', label: 'Finance', icon: '/PNG/PNG/finance.png' },
    { id: 'financial institution', label: 'Financial Institution', icon: '/PNG/PNG/financial institution.png' },
    { id: 'flight', label: 'Flight', icon: '/PNG/PNG/flight.png' },
    { id: 'general', label: 'General Announcement', icon: '/PNG/PNG/General_ Announcement .png' },
    { id: 'health', label: 'Health', icon: '/PNG/PNG/health.png' },
    { id: 'legal', label: 'Legal', icon: '/PNG/PNG/legal.png' },
    { id: 'military', label: 'Military', icon: '/PNG/PNG/military.png' },
    { id: 'missile', label: 'Missile', icon: '/PNG/PNG/missile.png' },
    { id: 'navy', label: 'Navy', icon: '/PNG/PNG/navy.png' },
    { id: 'navy ship', label: 'Navy Ship', icon: '/PNG/PNG/navyship.png' },
    { id: 'science', label: 'Science', icon: '/PNG/PNG/science.png' },
    { id: 'sports', label: 'Sports', icon: '/PNG/PNG/sports.png' },
    { id: 'technology', label: 'Technology', icon: '/PNG/PNG/technology.png' },
    { id: 'terror', label: 'Terror', icon: '/PNG/PNG/terror.png' },
    { id: 'vessel', label: 'Vessel', icon: '/PNG/PNG/vessel.png' },
    { id: 'warzone', label: 'Warzone', icon: '/PNG/PNG/warzone.png' },
  ];

  const CategoryFilterModal = ({ onClose }) => {
    const { state, dispatch } = useAppContext();
    const allSubCategories = Object.values(subCategories).flat();

    const [selectedSubCategories, setSelectedSubCategories] = useState(
      state.isFilterApplied ? state.selectedSubCategories : allSubCategories
    );

    useEffect(() => {
      setSelectedSubCategories(
        state.isFilterApplied ? state.selectedSubCategories : allSubCategories
      );
    },[state.selectedSubCategories, state.isFilterApplied]);

    


    const handleSubCategoryToggle = (subCategory) => {
      setSelectedSubCategories(prev => 
        prev.includes(subCategory)
          ? prev.filter(item => item !== subCategory)
          : [...prev, subCategory]
      );
    };

    const handleCategoryToggle = (category) => {
      const categorySubCategories = subCategories[category];
      const allSelected = categorySubCategories.every(subCat => selectedSubCategories.includes(subCat));
  
      if (allSelected) {
        setSelectedSubCategories(prev => prev.filter(item => !categorySubCategories.includes(item)));
      } else {
        setSelectedSubCategories(prev => [...new Set([...prev, ...categorySubCategories])]);
      }
    };

  const getIconForSubCategory = (subCategory) => {
    const categoryData = categoryIcons.find(cat => cat.id.toLowerCase() === subCategory.toLowerCase());
    return categoryData ? categoryData.icon : null;
  };

  const handleSubmit = () => {
    console.log('Submitting selected subcategories:', selectedSubCategories);
    dispatch({ type: 'SET_SELECTED_SUB_CATEGORIES', payload: selectedSubCategories });
    onClose();
  };

  const isCategoryFullySelected = (category) => {
    return subCategories[category].every(subCat => selectedSubCategories.includes(subCat));
  };

    return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Select Categories</h2>
          <button onClick={onClose} className="close-button">
            <X size={24} className='close-icon'/>
          </button>
        </div>

        <div className="scrollable-content">
          <div className="subcategory-section">
          <div className='subcategory-grid'>
          {Object.entries(subCategories).map(([category]) => (
            <div key={category} className="category-column">
              <label className="category-option">
                <input type="checkbox" checked={isCategoryFullySelected(category)} className="w-6 h-6 rounded border-gray-300 text-red-600 focus:ring-red-500"
                  onChange={() => handleCategoryToggle(category)} />
                <span className="category-label">{category}</span>
              </label>
            </div>
            ))}
            </div>
            <h3 className="section-title">Sub-Categories</h3>
            <div className='subcategory-grid'>
              {Object.entries(subCategories).map(([category, categorySubCategories]) => (
                <div key={category} className="subcategory-column">
                  <div className="subcategory-scroll">
                    {categorySubCategories.map(subCategory => (
                      <label key={subCategory} className="subcategory-option">
                        <input
                          type="checkbox"
                          checked={selectedSubCategories.includes(subCategory)}
                          onChange={() => handleSubCategoryToggle(subCategory)}
                          className="w-6 h-6 rounded border-gray-300 text-red-600 focus:ring-red-500"
                        />
                        <div className="subcategory-icon">
                          {getIconForSubCategory(subCategory) && (
                            <img src={getIconForSubCategory(subCategory)} alt={subCategory} className="subcategory-icon-image" />
                          )}
                        </div>
                        <span className="subcategory-label">{subCategory}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <button onClick={handleSubmit} className="submit-button">Apply Filters</button>
      </div>
    </div>
  );
};

export default CategoryFilterModal;