import React, { createContext, useContext, useReducer, useMemo } from 'react';

const SET_ARTICLES = 'SET_ARTICLES';
const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
const SET_SELECTED_SUB_CATEGORIES = 'SET_SELECTED_SUB_CATEGORIES';
const SET_SELECTED_ARTICLE = 'SET_SELECTED_ARTICLE';
const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
const SET_USER_LOCATION = 'SET_USER_LOCATION';
const SET_LOCATION_ERROR = 'SET_LOCATION_ERROR';
const SET_GEO_JSON_DATA = 'SET_GEO_JSON_DATA';
const SET_ZONE_COLORS = 'SET_ZONE_COLORS';
const SET_LOADING = 'SET_LOADING';
const SET_GROUPED_ARTICLES = 'SET_GROUPED_ARTICLES';
const SET_SELECTED_ZONE = 'SET_SELECTED_ZONE';
const SET_AVAILABLE_COUNTRIES = 'SET_AVAILABLE_COUNTRIES';


const setArticles = (articles) => ({ type: SET_ARTICLES, payload: articles });
const setActiveCategory = (activeCategory) => ({ type: SET_ACTIVE_CATEGORY, payload: activeCategory });
const setSelectedCountry = (country) => ({ type: SET_SELECTED_COUNTRY, payload: country });
const setSelectedSubcategories = (subcategory) => ({ type: SET_SELECTED_SUB_CATEGORIES, payload: subcategory });
const setSelectedArticle = (article) => ({ type: SET_SELECTED_ARTICLE, payload: article });
const setDialogOpen = (isOpen) => ({ type: SET_DIALOG_OPEN, payload: isOpen });
const setUserLocation = (location) => ({ type: SET_USER_LOCATION, payload: location });
const setLocationError = (error) => ({ type: SET_LOCATION_ERROR, payload: error });
const setGeoJsonData = (geoJSONData) => ({ type: SET_GEO_JSON_DATA, payload: geoJSONData });
const setZoneColors = (zoneColors) => ({ type: SET_ZONE_COLORS, payload: zoneColors });
const setLoading = (loading) => ({ type: SET_LOADING, payload: loading });
const setGroupedArticles = (groupedArticles) => ({ type: SET_GROUPED_ARTICLES, payload: groupedArticles });
const setSelectedZone = (zone) => ({ type: SET_SELECTED_ZONE, payload: zone });

const AppContext = createContext();

const initialState = {
  articles: [],
  filteredArticles: [],
  groupedArticles: null,
  selectedZone: null,
  activeCategory: 'all',
  selectedArticle: null,
  isDialogOpen: false,
  loading: true,
  userLocation: null,
  locationError: null,
  geoJSONData: {},
  zoneColors: {},
  selectedCategories: ['news', 'conflict'],
  selectedSubCategories: getAllSubCategories(),
  isFilterApplied: false,
  availableDates: [],
  currentDateIndex: 0,
  availableCountries: [{}], // Start with empty array
  selectedCountry: null, 
};

function appReducer(state, action) {
  try {
  switch(action.type){
    case 'SET_ARTICLES':
        return {
          ...state,
          articles: action.payload,
          filteredArticles: filterArticles(action.payload, state.activeCategory, state.selectedCountry, state.selectedSubCategories)
        };
    case 'SET_ACTIVE_CATEGORY':
      return {
        ...state, 
        activeCategory: action.payload,
        isFilterApplied:true,
        filteredArticles:filterArticles(state.articles, action.payload, state.selectedCountry,  state.selectedSubCategories)
    };
    case 'SET_AVAILABLE_COUNTRIES':
      return {
        ...state,
        availableCountries: action.payload,
        // Set the first available country as the selected country
        selectedCountry: action.payload.length > 0 ? action.payload[0] : null,
        filteredArticles: filterArticles(
          state.articles,
          state.activeCategory,
          action.payload.length > 0 ? action.payload[0] : null,
          state.selectedSubCategories
        )
      };
    case 'SET_SELECTED_COUNTRY':
    return {
      ...state,
      selectedCountry: state.availableCountries.includes(action.payload) 
        ? action.payload 
        : (state.availableCountries[0] || null),
      filteredArticles: filterArticles(
        state.articles, 
        state.activeCategory, 
        action.payload, 
        state.selectedSubCategories
      ),
      groupedArticles: null,
    };
      case 'SET_MAP_VIEW':
        return {
          ...state,
          mapCenter: action.payload.center,
          mapZoom: action.payload.zoom
        };
        case 'SET_SELECTED_COUNTRY':
      return {
        ...state,
        selectedCountry: action.payload,
      };
    case 'SET_SELECTED_CATEGORIES':
      const newSelectedCategories = action.payload;
      let newSelectedSubCategories = [...state.selectedSubCategories];
      if (newSelectedCategories.length === 2) {
        newSelectedSubCategories = getAllSubCategories();
      } 
      else if (newSelectedCategories.length === 1) {
        const deselectedCategory = ['news', 'conflict'].find(cat => !newSelectedCategories.includes(cat));
        newSelectedSubCategories = newSelectedSubCategories.filter(subCat => 
          !getSubCategoriesForCategory(deselectedCategory).includes(subCat)
        );
      }
      else {
        newSelectedSubCategories = [];
      }
      return {
        ...state,
        selectedCategories: newSelectedCategories,
        selectedSubCategories: newSelectedSubCategories,
        filteredArticles: filterArticles(state.articles, state.activeCategory, state.selectedCountry, newSelectedSubCategories)
      };
    case 'SET_SELECTED_SUB_CATEGORIES':
      return {
        ...state,
        selectedSubCategories: action.payload,
        isFilterApplied: true,
        filteredArticles: filterArticles(state.articles, state.activeCategory, state.selectedCountry, action.payload)
      };
      case 'SET_AVAILABLE_DATES':
      return {
        ...state,
        availableDates: action.payload,
        currentDateIndex: 0 // Start with most recent date
      };
    case 'SET_CURRENT_DATE_INDEX':
      return {
        ...state,
        currentDateIndex: action.payload
      };

      case 'SET_FILTERED_ARTICLES':
        return { ...state, filteredArticles: action.payload };
      case 'SET_GROUPED_ARTICLES':
      return { ...state, groupedArticles: action.payload , filteredArticles: filterArticles(action.payload, state.activeCategory, state.selectedCountry, state.selectedSubCategories)
       };
      case 'SET_SELECTED_ZONE':
        return { ...state, selectedZone: action.payload };
        case SET_SELECTED_ARTICLE:
          return { 
            ...state, 
            selectedArticle: action.payload,
          };
      case 'SET_DIALOG_OPEN':
        return { ...state, isDialogOpen: action.payload };
      case 'SET_USER_LOCATION':
        return { ...state, userLocation: action.payload };
      case 'SET_LOCATION_ERROR':
        return { ...state, locationError: action.payload };
      case 'SET_GEO_JSON_DATA':
        return { ...state, geoJSONData: action.payload };
      case 'SET_ZONE_COLORS':
        return { ...state, zoneColors: action.payload };
      case 'SET_LOADING':
          return { ...state, loading: action.payload };
    default:
      return state;
  }
}catch (error) {
  console.error('Error in reducer:', error);
  return state;
}
}

function filterArticles(articles, activeCategory, country, selectedSubCategories) {
  if (!country) return [];
  return articles.filter(article => {
    const categoryMatch = activeCategory === 'all' || article.subcategory === activeCategory;
    const countryMatch = country === 'all' || article.country === country;
    const subCategoryMatch = selectedSubCategories.includes(article.subcategory);
    return categoryMatch && countryMatch && subCategoryMatch;
  });
}

function getAllSubCategories() {
  return [
    ...getSubCategoriesForCategory('news'),
    ...getSubCategoriesForCategory('conflict')
  ];
}


function getSubCategoriesForCategory(category) {
  const subCategories = {
    "news": ["business", "elections", "entertainment", "finance", "financial institution", "general", "health",
      "legal", "science", "sports", "technology", "emergency", "accident", "flight", "vessel"],
    "conflict": ["air defence", "accident/death", "airforce", "military", "missile", "navy", "navy ship",
      "terror", "warzone", "explosion"]
  };
  return subCategories[category] || [];
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const contextValue = useMemo(() => ({
    state,
    dispatch,
    actions: {
      setArticles: (articles) => dispatch(setArticles(articles)),
      setActiveCategory: (category) => dispatch(setActiveCategory(category)),
      setSelectedCountry: (country) => {
        dispatch(setSelectedCountry(country));
        dispatch({ type: 'SET_GROUPED_ARTICLES', payload: null }); },
      setSelectedSubcategories: (subcategories) => dispatch(setSelectedSubcategories(subcategories)),
      setSelectedArticle: (article) => {
        dispatch(setSelectedArticle(article));
        dispatch(setDialogOpen(true));
      },
      setDialogOpen: (isOpen) => dispatch(setDialogOpen(isOpen)),
      setUserLocation: (location) => dispatch(setUserLocation(location)),
      setLocationError: (error) => dispatch(setLocationError(error)),
      setGeoJsonData: (data) => dispatch(setGeoJsonData(data)),
      setZoneColors: (colors) => dispatch(setZoneColors(colors)),
      setLoading: (loading) => dispatch(setLoading(loading)),
      setGroupedArticles: (articles) => dispatch(setGroupedArticles(articles)),
      setSelectedZone: (zone) => dispatch(setSelectedZone(zone)),
      setSelectedMarker: (article) => {
        dispatch(setSelectedArticle(article));
        dispatch(setDialogOpen(true));
      },
      setSelectedZoneArticles: (zone, articles) => {
        dispatch(setSelectedZone(zone));
        dispatch(setGroupedArticles(articles));
      },
      setAvailableCountries: (countriesData) => {
        dispatch({
          type: SET_AVAILABLE_COUNTRIES,
          payload: countriesData.map(country => country.code.toLowerCase())
        });
      }
    }
  }), [state, dispatch]);
  return (  
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
}