import React from 'react';
import MapComponent from './MapComponent';
import { AppProvider } from './AppContext';

const App = () => {
  return (
    <AppProvider>
        <div style={{overflow:"hidden"}}>
          <MapComponent />
        </div>
    </AppProvider>
  );
};

export default App;