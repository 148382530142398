import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Rectangle, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

const MinimapControl = ({ mainMap, position = 'bottomright' }) => {
  const minimap = useMap();
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [rectangleBounds, setRectangleBounds] = useState(null);

  // Get rectangle bounds based on main map's viewport
  const getViewportBounds = useCallback(() => {
    if (!mainMap) return null;
    return mainMap.getBounds();
  }, [mainMap]);

  // Handle minimap zoom events
  const mapEvents = useMapEvents({
    zoom: () => {
      // Update rectangle bounds when minimap zooms
      if (mainMap) {
        const bounds = getViewportBounds();
        setRectangleBounds(bounds);
      }
    }
  });

  // Sync minimap when main map moves
  useEffect(() => {
    if (!mainMap) return;

    const onMainMapMove = () => {
      const mainMapCenter = mainMap.getCenter();
      const bounds = getViewportBounds();
      setRectangleBounds(bounds);
      
      minimap.setView(mainMapCenter, mainMap.getZoom() - 4, {
        animate: false
      });
    };

    mainMap.on('move', onMainMapMove);
    mainMap.on('zoom', onMainMapMove);

    // Initial sync
    onMainMapMove();

    return () => {
      mainMap.off('move', onMainMapMove);
      mainMap.off('zoom', onMainMapMove);
    };
  }, [mainMap, minimap, getViewportBounds]);

  useEffect(() => {
    if (!minimap || !mainMap) return;

    const container = minimap.getContainer();

    const onTouchStart = (e) => {
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        setIsDragging(true);
        setDragStart(L.point(touch.clientX, touch.clientY));
        e.preventDefault();
      }
    };

    const onTouchMove = (e) => {
      if (!isDragging || !dragStart || e.touches.length !== 1) return;

      const touch = e.touches[0];
      const currentPoint = L.point(touch.clientX, touch.clientY);
      const offset = currentPoint.subtract(dragStart);

      // Convert the pixel offset to LatLng
      const centerPoint = minimap.getCenter();
      const targetPoint = minimap.latLngToContainerPoint(centerPoint).subtract(offset);
      const newCenter = minimap.containerPointToLatLng(targetPoint);

      // Update both maps
      mainMap.panTo(newCenter, { animate: true });
      minimap.panTo(newCenter, { animate: true });

      setDragStart(currentPoint);
      e.preventDefault();
    };

    const onTouchEnd = () => {
      setIsDragging(false);
      setDragStart(null);
    };

    // Add touch event listeners
    container.addEventListener('touchstart', onTouchStart, { passive: false });
    document.addEventListener('touchmove', onTouchMove, { passive: false });
    document.addEventListener('touchend', onTouchEnd, { passive: false });

    // Existing mouse event listeners
    const onMouseDown = (e) => {
      setIsDragging(true);
      setDragStart(L.point(e.clientX, e.clientY));
      document.body.style.userSelect = 'none';
    };

    const onMouseMove = (e) => {
      if (!isDragging || !dragStart) return;

      const currentPoint = L.point(e.clientX, e.clientY);
      const offset = currentPoint.subtract(dragStart);

      // Convert the pixel offset to LatLng
      const centerPoint = minimap.getCenter();
      const targetPoint = minimap.latLngToContainerPoint(centerPoint).subtract(offset);
      const newCenter = minimap.containerPointToLatLng(targetPoint);

      // Update both maps
      mainMap.panTo(newCenter, { animate: true });
      minimap.panTo(newCenter, { animate: true });

      setDragStart(currentPoint);
    };

    const onMouseUp = () => {
      setIsDragging(false);
      setDragStart(null);
      document.body.style.userSelect = '';
    };

    container.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      // Remove all event listeners
      container.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      container.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, dragStart, mainMap, minimap]);

  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {rectangleBounds && (
        <Rectangle
          bounds={rectangleBounds}
          pathOptions={{
            color: '#ff7800',
            weight: 1,
            fillOpacity: 0.1,
            className: 'minimap-rectangle'
          }}
        />
      )}
    </>
  );
};

const MapGuide = () => {
  const mainMap = useMap();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  const mapGuideStyle = isMobile 
    ? {
        position: 'absolute',
        bottom: '25px',
        right: '10px',
        zIndex: 1000,
        border: '1px solid black',
        borderRadius: '0px',
        background: 'white',
        width: '102px',
        height: '102px',
      }
    : {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        border: '1px solid black',
        borderRadius: '4px',
        background: 'white',
      };

  return (
    <div
      style={mapGuideStyle}
      className="map-guide-container"
    >
      <MapContainer
        style={{
          width: isMobile ? '100px' : '150px',
          height: isMobile ? '100px' : '150px',
        }}
        center={mainMap.getCenter()}
        zoom={mainMap.getZoom() - 4}
        zoomControl={false}
        dragging={true}
        doubleClickZoom={true}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <MinimapControl mainMap={mainMap} />
      </MapContainer>
    </div>
  );
};

export default MapGuide;