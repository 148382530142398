import React, { lazy, Suspense, useEffect, useState, useCallback, useMemo,useRef } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, useMapEvents,Polygon,Circle,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './App.css';
import FilterByCountry from './components/FilterByCountry/FilterByCountry';
import CategoryFilterModal from './CategoryFilterModal';
import { useAppContext } from './AppContext';
import { Geolocation } from '@capacitor/geolocation';
import { App } from '@capacitor/app';
import { X } from 'lucide-react';
import ArticlesDebugger from './ArticlesDebugger';
import MapGuide from './MapGuide';

const API_BASE_URL = 'https://api.mapmynews.com/test-app.php';
const FETCH_COUNTRIES_URL = 'https://api.mapmynews.com/fetch_selected_countries.php';

const SideBar = lazy(() => import('./components/Sidebar/SideBar'));
const Dialog = lazy(() => import('./components/Dialog/Dialog'));

// const COUNTRY_COORDINATES = {
//   'in': { center: [20.593684, 78.96288], zoom: 5 },  // India
//   'cn': { center: [35.8617, 104.1954], zoom: 4 },    // China
//   'ua': { center: [48.3794, 31.1656], zoom: 6 },     // Ukraine
//   'pk': { center: [30.3753, 69.3451], zoom: 6 },     // Pakistan
//   'np': { center: [28.3949, 84.1240], zoom: 7 },     // Nepal
//   'bd': { center: [23.6850, 90.3563], zoom: 7 },     // Bangladesh
//   'bt': { center: [27.5142, 90.4336], zoom: 8 }      // Bhutan
// };

const COUNTRY_COORDINATES = {
  'ar': { name: 'Argentina', center: [-38.416097, -63.616672], zoom: 4 },
  'au': { name: 'Australia', center: [-25.274398, 133.775136], zoom: 4 },
  'at': { name: 'Austria', center: [47.516231, 14.550072], zoom: 7 },
  'be': { name: 'Belgium', center: [50.503887, 4.469936], zoom: 7 },
  'br': { name: 'Brazil', center: [-14.235004, -51.925280], zoom: 4 },
  'bg': { name: 'Bulgaria', center: [42.733883, 25.485830], zoom: 7 },
  'ca': { name: 'Canada', center: [56.130366, -106.346771], zoom: 3 },
  'cn': { name: 'China', center: [35.861660, 104.195397], zoom: 4 },
  'co': { name: 'Colombia', center: [4.570868, -74.297333], zoom: 6 },
  'cz': { name: 'Czech Republic', center: [49.817492, 15.472962], zoom: 7 },
  'eg': { name: 'Egypt', center: [26.820553, 30.802498], zoom: 6 },
  'fr': { name: 'France', center: [46.227638, 2.213749], zoom: 6 },
  'de': { name: 'Germany', center: [51.165691, 10.451526], zoom: 6 },
  'gr': { name: 'Greece', center: [39.074208, 21.824312], zoom: 7 },
  'hk': { name: 'Hong Kong', center: [22.396428, 114.109497], zoom: 10 },
  'hu': { name: 'Hungary', center: [47.162494, 19.503304], zoom: 7 },
  'in': { name: 'India', center: [20.593684, 78.96288], zoom: 5 },
  'id': { name: 'Indonesia', center: [-0.789275, 113.921327], zoom: 5 },
  'ir': { name: 'Ireland', center: [53.412910, -8.243890], zoom: 7 },
  'il': { name: 'Israel', center: [31.046051, 34.851612], zoom: 7 },
  'it': { name: 'Italy', center: [41.871940, 12.567380], zoom: 6 },
  'jp': { name: 'Japan', center: [36.204824, 138.252924], zoom: 5 },
  'lv': { name: 'Latvia', center: [56.879635, 24.603189], zoom: 7 },
  'lt': { name: 'Lithuania', center: [55.169438, 23.881275], zoom: 7 },
  'my': { name: 'Malaysia', center: [4.210484, 101.975766], zoom: 6 },
  'mx': { name: 'Mexico', center: [23.634501, -102.552784], zoom: 5 },
  'ma': { name: 'Morocco', center: [31.791702, -7.092620], zoom: 6 },
  'nl': { name: 'Netherlands', center: [52.132633, 5.291266], zoom: 7 },
  'nz': { name: 'New Zealand', center: [-40.900557, 174.885971], zoom: 5 },
  'ng': { name: 'Nigeria', center: [9.081999, 8.675277], zoom: 6 },
  'no': { name: 'Norway', center: [60.472024, 8.468946], zoom: 5 },
  'ph': { name: 'Philippines', center: [12.879721, 121.774017], zoom: 6 },
  'pl': { name: 'Poland', center: [51.919438, 19.145136], zoom: 6 },
  'pt': { name: 'Portugal', center: [39.399872, -8.224454], zoom: 7 },
  'ro': { name: 'Romania', center: [45.943161, 24.966760], zoom: 7 },
  'sa': { name: 'Saudi Arabia', center: [23.885942, 45.079162], zoom: 5 },
  'rs': { name: 'Serbia', center: [44.016521, 21.005859], zoom: 7 },
  'sg': { name: 'Singapore', center: [1.352083, 103.819836], zoom: 11 },
  'sk': { name: 'Slovakia', center: [48.669026, 19.699024], zoom: 7 },
  'si': { name: 'Slovenia', center: [46.151241, 14.995463], zoom: 8 },
  'za': { name: 'South Africa', center: [-30.559482, 22.937506], zoom: 5 },
  'kr': { name: 'South Korea', center: [35.907757, 127.766922], zoom: 7 },
  'se': { name: 'Sweden', center: [60.128161, 18.643501], zoom: 5 },
  'ch': { name: 'Switzerland', center: [46.818188, 8.227512], zoom: 8 },
  'tw': { name: 'Taiwan', center: [23.697810, 120.960515], zoom: 7 },
  'th': { name: 'Thailand', center: [15.870032, 100.992541], zoom: 6 },
  'tr': { name: 'Turkey', center: [38.963745, 35.243322], zoom: 6 },
  'ae': { name: 'UAE', center: [23.424076, 53.847818], zoom: 7 },
  'ua': { name: 'Ukraine', center: [48.379433, 31.165581], zoom: 6 },
  'gb': { name: 'United Kingdom', center: [55.378051, -3.435973], zoom: 5 },
  'us': { name: 'United States', center: [37.090240, -95.712891], zoom: 4 },
  've': { name: 'Venezuela', center: [6.423750, -66.589730], zoom: 6 }
};

const subcategoryIcons = {
  'accident': '/PNG/PNG/accident.png',
  'accident/death': '/PNG/PNG/accident_death.png',
  'air defence': '/PNG/PNG/air defence.png',
  'airforce': '/PNG/PNG/air force.png',
  'business': '/PNG/PNG/business.png',
  'elections': '/PNG/PNG/elections.png',
  'emergency': '/PNG/PNG/emergency.png',
  'entertainment': '/PNG/PNG/entertainment.png',
  'explosion': '/PNG/PNG/explosion.png',
  'finance': '/PNG/PNG/finance.png',
  'financial institution': '/PNG/PNG/financial institution.png',
  'flight': '/PNG/PNG/flight.png',
  'general': '/PNG/PNG/General_ Announcement .png',
  'health': '/PNG/PNG/health.png',
  'legal': '/PNG/PNG/legal.png',
  'military': '/PNG/PNG/military.png',
  'missile': '/PNG/PNG/missile.png',
  'navy': '/PNG/PNG/navy.png',
  'navy ship': '/PNG/PNG/navyship.png',
  'science': '/PNG/PNG/science.png',
  'sports': '/PNG/PNG/sports.png',
  'technology': '/PNG/PNG/technology.png',
  'terror': '/PNG/PNG/terror.png',
  'vessel': '/PNG/PNG/vessel.png',
  'warzone': '/PNG/PNG/warzone.png',
};


const preloadMapTiles = (centerLat, centerLng, zoom) => {
  const tileUrl = (x, y, z) => `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  const numTiles = Math.pow(2, zoom);
  const x = Math.floor((centerLng + 180) / 360 * numTiles);
  const y = Math.floor((1 - Math.log(Math.tan(centerLat * Math.PI / 180) + 1 / Math.cos(centerLat * Math.PI / 180)) / Math.PI) / 2 * numTiles);

  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = tileUrl(x + i, y + j, zoom);
      document.head.appendChild(link);
    }
  }
};

const MapEvents = () => {
  const map = useMap();
  const { state, dispatch } = useAppContext();
  const prevCenter = useRef(state.mapCenter);
  const prevZoom = useRef(state.mapZoom);

  // First useEffect remains the same...

  useEffect(() => {
    // Enhanced validation and error handling
    if (!map) {
      console.warn('Map not initialized');
      return;
    }

    if (!state.selectedCountry) {
      console.warn('No country selected');
      return;
    }

    const countryCoords = COUNTRY_COORDINATES[state.selectedCountry];

    if (!countryCoords) {
      console.warn(`No coordinates found for country: ${state.selectedCountry}`);
      return;
    }

    const { center, zoom } = countryCoords;

    const isValidCoordinates = (coords) => {
      return (
        Array.isArray(coords) &&
        coords.length === 2 &&
        typeof coords[0] === 'number' &&
        typeof coords[1] === 'number' &&
        !isNaN(coords[0]) &&
        !isNaN(coords[1]) &&
        coords[0] >= -90 &&
        coords[0] <= 90 &&
        coords[1] >= -180 &&
        coords[1] <= 180
      );
    };


    // Add a fallback mechanism
    const fallbackCoordinates = {
      // Add a default set of coordinates or global view
      center: [0, 0],
      zoom: 2
    };

    try {
      if (isValidCoordinates(center)) {
        map.flyTo(center, zoom, {
          duration: 1.5,
          easeLinearity: 0.25
        });
      } else {
        console.error(`Invalid coordinates for ${state.selectedCountry}:`, center);
        
        // Fallback to default view
        map.flyTo(fallbackCoordinates.center, fallbackCoordinates.zoom, {
          duration: 1.5,
          easeLinearity: 0.25
        });
      }
    } catch (error) {
      console.error('Error flying to location:', error, {
        country: state.selectedCountry,
        center: center,
        zoom: zoom
      });

      // Additional fallback
      try {
        map.setView(fallbackCoordinates.center, fallbackCoordinates.zoom);
      } catch (fallbackError) {
        console.error('Fallback view setting failed:', fallbackError);
      }
    }
  }, [state.selectedCountry, map]);

  return null;
};


const getLocationErrorMessage = (error) => {
  const errorMessages = {
    1: 'Location permission denied. Please enable location services.',
    2: 'Location information is unavailable.',
    3: 'The request to get user location timed out.',
  };
  return errorMessages[error.code] || 'Failed to get user location.';
};

L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const getMarkerIcon = (subcategory) => {
  const iconUrl = subcategoryIcons[subcategory.toLowerCase()] || '/PNG/PNG/default.png';
  return L.icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });
};

const SetViewOnUser = React.memo(({ coords }) => {
  const map = useMap();
  useEffect(() => {
    if (coords) {
      map.setView(coords, 10, {
        animate: true,
        duration: 1
      });
    }
  }, [coords, map]);
  return null;
});

const MapContent = React.memo(() => {
  const { state, dispatch } = useAppContext();
  const map = useMap();

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleZoneClick = useCallback((zone) => {
    const zoneArticles = state.articles.filter(article => article.zone === zone);
    const otherArticles = state.articles.filter(article => article.zone !== zone);
    dispatch({ type: 'SET_GROUPED_ARTICLES', payload: [...zoneArticles, ...otherArticles] });
  }, [state.articles, handleMarkerClick, dispatch]);

  const renderZoneGeometry = useCallback((zone, data, color) => {
    if (data.type === 'Point') {
      return (
        <Circle
          center={[data.coordinates[1], data.coordinates[0]]}
          radius={20000}
          pathOptions={{ color: color, fillColor: color, fillOpacity: 0.2 }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
        </Circle>
      );
    } else if (data.type === 'Polygon') {
      return (
        <Polygon
          positions={data.coordinates[0].map(coord => [coord[1], coord[0]])}
          pathOptions={{ color: color }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
          <Popup>{zone}</Popup>
        </Polygon>
      );
    } else {
      console.error('Unsupported GeoJSON type:', data.type);
      return null;
    }
  }, [handleZoneClick]);

  const groupMarkersByLocation = useCallback((articles) => {
    const groupedMarkers = {};
    articles.forEach((article) => {
      if (article.latitude && article.longitude) {
        const key =`${article.latitude},${article.longitude}`;
        if (!groupedMarkers[key]) {
          groupedMarkers[key] = [];
        }
        groupedMarkers[key].push(article);
      }
    });
    return groupedMarkers;
  }, []);

  const renderGroupedMarkers = useCallback((groupedMarkers) => {
    return Object.entries(groupedMarkers).map(([key, articles]) => {
      const [lat, lng] = key.split(',').map(Number);
      const offsetStep = 10;

      return articles.map((article, index) => {
        const offset = index * offsetStep;
        const icon = getMarkerIcon(article.subcategory);
        const adjustedLatLng = map.containerPointToLatLng(
          map.latLngToContainerPoint([lat, lng]).add([offset, 0])
        );

        return (
          <Marker
            key={`${article.id}-${index}`}
            position={adjustedLatLng}
            icon={icon}
            eventHandlers={{
              click: () => handleMarkerClick(article)
            }}
          />
        );
      });
    });
  }, [map, handleMarkerClick]);

  const filteredZones = useMemo(() => {
    if (!state.articles || !state.geoJSONData) return {};
    
    const countryZones = state.articles
      .filter(article => article.country === state.selectedCountry)
      .map(article => article.zone)
      .filter(Boolean);
    
    const filteredGeoJSON = {};
    countryZones.forEach(zone => {
      if (state.geoJSONData[zone]) {
        filteredGeoJSON[zone] = state.geoJSONData[zone];
      }
    });
    
    return filteredGeoJSON;
  }, [state.articles, state.geoJSONData, state.selectedCountry]);

  const groupedMarkers = useMemo(() => groupMarkersByLocation(state.filteredArticles), [state.filteredArticles, groupMarkersByLocation]);


  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {Object.entries(filteredZones).map(([zone, data]) => (
        <React.Fragment key={zone}>
          {renderZoneGeometry(zone, data, state.zoneColors[zone] || 'blue')}
        </React.Fragment>
      ))}
      
      {renderGroupedMarkers(groupedMarkers)}
      <MapGuide/>
    </>
  );
});


const MapComponent = () => {
  const { state, dispatch } = useAppContext();
  const [map, setMap] = useState(null);
  const mapRef=useRef(null);
  const [activeView, setActiveView] = useState('map');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [localState, setLocalState] = useState({
    isDialogOpen: false,
    isCategoryModalOpen: false, 
    loading: true,
    error: null,
    locationError: null, 
    selectedZoneArticles: [],
    showLocationError: false,
    isLocationOn: false,
    hasLocationPermission: false,
    locationErrorType: null,
    showLocationBanner: false ,
    isLocationFetched: false,
    currentDate: new Date(),
    isFirstPage: true, 
    hasOlderNews: true,
    availableCountries: []
  });

  const api = useMemo(() => axios.create({
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' }
  }), []);

  const handleViewToggle =(view)=>{
    console.log(view);
    setActiveView(view)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(isMobile);
  

  const fetchAvailableCountries = useCallback(async () => {
    try {
      const response = await axios.get(FETCH_COUNTRIES_URL);
      
      if (response.data && response.data.selectedCountries) {

        dispatch({
          type: 'SET_AVAILABLE_COUNTRIES',
          payload: response.data.selectedCountries
        });

        // Default to first available country or India as fallback
        const defaultCountry = response.data.selectedCountries[0];

        dispatch({ 
          type: 'SET_SELECTED_COUNTRY', 
          payload: defaultCountry 
        });

        return response.data.selectedCountries;
      }
    } catch (error) {
      console.error('Error fetching available countries:', error);
      setLocalState(prev => ({
        ...prev,
        error: 'Failed to fetch available countries. Please try again later.'
      }));
      return {};
    }
  }, [dispatch]);



  const formatDateForAPI = useCallback((date) => {
    return date.toISOString().split('T')[0];
  }, []);

  const getYesterday = useCallback(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }, []);

  const mapCenter = useMemo(() => [20.593684, 78.96288], []); 
  const mapZoom = useMemo(() => 5, []);

  const availableCountries =state.availableCountries;  

  const requestLocationPermission = useCallback(async () => {
    try {
      const permissionStatus = await Geolocation.requestPermissions();
      const isGranted = permissionStatus.location === 'granted';
      setLocalState(prev => ({ ...prev, hasLocationPermission: isGranted }));
      if (isGranted) {
        const isLocationEnabled = await Geolocation.isLocationEnabled();
        setLocalState(prev => ({ 
          ...prev, 
          isLocationOn: isLocationEnabled,
          showLocationBanner: !isLocationEnabled,
          showLocationError: !isLocationEnabled
        }));
      }
      return isGranted;
    } catch (error) {
      console.error('Error requesting location permission:', error);
      return false;
    }
  }, []);


  const getUserLocation = useCallback(async () => {
    try {
      setLocalState(prev => ({ ...prev, isLocationFetched: true }));
      const position = await Geolocation.getCurrentPosition();
      if (position) {
        dispatch({ 
          type: 'SET_USER_LOCATION', 
          payload: [position.coords.latitude, position.coords.longitude] 
        });
        if (mapRef.current) {
          mapRef.current.setView(
            [position.coords.latitude, position.coords.longitude],
            10,
            { animate: true }
          );
        }
        setLocalState(prev => ({ ...prev, isLocationFetched: false }));
      }
    } catch (error) {
      console.error('Error getting user location:', error);
      dispatch({ type: 'SET_LOCATION_ERROR', payload: getLocationErrorMessage(error) });
      setLocalState(prev => ({ ...prev, isLocationFetched: true }));
    }
  }, [dispatch]);

  const filteredArticles = useMemo(() => {
    if (!state.articles || !Array.isArray(state.articles)) {
      return [];
    }
    return state.articles.filter(article => {
      const categoryMatch = state.activeCategory === 'all' || article.category === state.activeCategory;
      const countryMatch = !state.selectedCountry || article.country === state.selectedCountry;
      const selectedCategoryMatch = state.selectedCategories.length === 0 || state.selectedCategories.includes(article.category);
      const selectedSubCategoryMatch = state.selectedSubCategories.length === 0 || state.selectedSubCategories.includes(article.category);
      return categoryMatch && countryMatch && selectedCategoryMatch && selectedSubCategoryMatch;
    });
  }, [state.articles, state.selectedCountry, state.activeCategory, state.selectedCategories, state.selectedSubCategories]);

  const handleCloseErrorBanner = useCallback(() => {    
    setLocalState(prev => ({ ...prev, showLocationBanner: false }));
  }, []);

  const checkLocationStatus = useCallback(async () => {
    try {
      const permissionStatus = await Geolocation.checkPermissions();
      const isLocationEnabled = await Geolocation.isLocationEnabled();
      
      setLocalState(prev => ({
        ...prev,
        hasLocationPermission: permissionStatus.location === 'granted',
        isLocationOn: isLocationEnabled,
        showLocationBanner: false,
        locationErrorType: permissionStatus.location !== 'granted' ? 'permission' : 'locationOff'
      }));
  
      return { 
        hasPermission: permissionStatus.location === 'granted', 
        isLocationOn: isLocationEnabled 
      };
    } catch (error) {
      console.error('Error checking location status:', error);
      return { hasPermission: false, isLocationOn: false };
    }
  }, []);

  const getGeoJSONFromZone = useCallback(async (zoneName) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: zoneName,
          format: 'json',
          polygon_geojson: 1,
        },
      });

      return response.data.length > 0 ? response.data[0].geojson : null;
    } catch (error) {
      console.error('Error fetching geojson:', error);
      return null;
    }
  }, []);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const response = await api.get('https://api.mapmynews.com/test.php'); 
      
      if (response.data && Array.isArray(response.data)) {
        dispatch({ type: 'SET_AVAILABLE_DATES', payload: response.data });
      }
    } catch (error) {
      console.error('Error fetching available dates:', error);
      setLocalState(prev => ({
        ...prev,
        error: 'Failed to fetch available dates. Please try again later.'
      }));
    }
  }, [api, dispatch]);


  const fetchArticlesAndGeoJSON = useCallback(async (date, attempts = 0, maxAttempts = 7)=> {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const formattedDate = formatDateForAPI(date);
      const response = await api.get('', {
        params: {
          date: formattedDate
        }
      });

    if (!response.data) {
      throw new Error('No data received from API');
    }
      const fetchedArticles = response.data;
      
      if (!Array.isArray(fetchedArticles)) {
        console.error('Invalid data format:', fetchedArticles);
        throw new Error('Invalid data format received from API');
      }


  if (fetchedArticles.length === 0 && attempts < maxAttempts) {
      const previousDate = new Date(date);
      previousDate.setDate(previousDate.getDate() - 1);
      
      dispatch({ 
        type: 'SET_LOADING', 
        payload: { 
          status: true, 
          message: `No news found for ${formattedDate}, checking ${formatDateForAPI(previousDate)}...` 
        } 
      });
      
      return fetchArticlesAndGeoJSON(previousDate, attempts + 1, maxAttempts);
    }

    if (fetchedArticles.length > 0) {
      setLocalState(prev => ({
        ...prev,
        currentDate: date,
        hasOlderNews: true
      }));
    } else {
      setLocalState(prev => ({
        ...prev,
        hasOlderNews: false
      }));
    }

      dispatch({ type: 'SET_ARTICLES', payload: fetchedArticles });
      dispatch({ type: 'SET_LOADING', payload: false });

      const processGeoJSON = async () => {
        const uniqueZones = new Set(fetchedArticles.map((article) => article.zone));
        const geoData = {};
        const colors = {};
        
        await Promise.all(Array.from(uniqueZones).map(async (zone) => {
          if (zone) {
            try {
              const geoJSON = await getGeoJSONFromZone(zone);
              if (geoJSON) {
                geoData[zone] = geoJSON;
                const articleWithColor = fetchedArticles.find(article => article.zone === zone && article.color);
                colors[zone] = articleWithColor ? articleWithColor.color : 'blue';
              }
            } catch (error) {
              console.warn(`Failed to fetch GeoJSON for zone ${zone}:`, error);
            }
          }
        }));

        dispatch({ type: 'SET_GEO_JSON_DATA', payload: geoData });
        dispatch({ type: 'SET_ZONE_COLORS', payload: colors });
      };

      processGeoJSON();

    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, [api, dispatch, formatDateForAPI]);

  useEffect(() => {
    const initializeMap = async () => {

      const coordinates = await fetchAvailableCountries();
      const defaultCountry = localState.availableCountries[0] || 'in';

      await fetchAvailableDates();
      preloadMapTiles(mapCenter[0], mapCenter[1], mapZoom);
      
      const { hasPermission, isLocationOn } = await checkLocationStatus();
      if (!hasPermission) {
        const granted = await requestLocationPermission();
        if (granted) {
          await getUserLocation();
        }
        else{
          setLocalState(prev => ({ ...prev, showLocationBanner: true }));
        }
      } else if (isLocationOn) {
        await getUserLocation();
      }
      else{
        setLocalState(prev => ({ ...prev, showLocationBanner: true }));
      }
      
      const yesterday = getYesterday();
      setLocalState(prev => ({ ...prev, currentDate: yesterday }));
      await fetchArticlesAndGeoJSON(yesterday);
    };
    
    initializeMap();
  }, [checkLocationStatus,fetchAvailableCountries, requestLocationPermission, getUserLocation, fetchArticlesAndGeoJSON,fetchAvailableDates, mapCenter, mapZoom, getYesterday]);

  const handleDateChange = useCallback(async (direction) => {
    const { availableDates, currentDateIndex } = state;

    let newIndex = currentDateIndex;
  
    if (direction === 'previous' && currentDateIndex < availableDates.length - 1) {
      newIndex += 1;
    } else if (direction === 'next' && currentDateIndex > 0) {
      newIndex -= 1;
    } else {
      console.warn("No more dates in the given direction.");
      return;
    }
  
    dispatch({ type: 'SET_CURRENT_DATE_INDEX', payload: newIndex });
  
    const selectedDate = availableDates[newIndex];
    await fetchArticlesAndGeoJSON(new Date(selectedDate));
    if (isMobile) {
      setActiveView('map')
    }

  }, [state.availableDates, state.currentDateIndex, dispatch, fetchArticlesAndGeoJSON]);
  
  useEffect(() => {
    let watchId;
    
    const watchLocation = async () => {
      try {
        watchId = await Geolocation.watchPosition(
          { enableHighAccuracy: true },
          (position) => {
            if (position) {
              dispatch({ 
                type: 'SET_USER_LOCATION', 
                payload: [position.coords.latitude, position.coords.longitude] 
              });
              if (mapRef.current) {
                mapRef.current.setView(
                  [position.coords.latitude, position.coords.longitude],
                  10,
                  { animate: true }
                );
              }
            }
          }
        );
      } catch (error) {
        console.error('Error watching location:', error);
      }
    };

    watchLocation();

    return () => {
      if (watchId !== undefined) {
        Geolocation.clearWatch({ id: watchId });
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const checkLocationStatus = async () => {
      try {
        const isLocationEnabled = await Geolocation.isLocationEnabled();
        setLocalState(prev => ({
          ...prev,
          showLocationBanner: false
        }));
      } catch (error) {
        console.error('Error checking location status:', error);
        setLocalState(prev => ({
          ...prev,
          showLocationBanner: true
        }));
      }
    };

    checkLocationStatus();
  }, []);

  useEffect(() => {
    const requestPermissionAndWatch = async () => {
      try {
        const permissionStatus = await Geolocation.requestPermissions();
        setLocalState(prev => ({
          ...prev,
          hasLocationPermission: permissionStatus.location === 'granted'
        }));

        App.addListener('appStateChange', async ({ isActive }) => {
          if (isActive) {
            const status = await checkLocationStatus();
            if (status.isLocationOn && status.hasPermission) {
              getUserLocation();
            }
          }
        });
      } catch (error) {
        console.error('Error requesting location permission:', error);
      }
    };

    requestPermissionAndWatch();

    return () => {
      App.removeAllListeners();
    };
  }, [checkLocationStatus, getUserLocation]);


  useEffect(() => {
    preloadMapTiles(mapCenter[0], mapCenter[1], mapZoom);
    getUserLocation();
    fetchArticlesAndGeoJSON();
  }, [getUserLocation, fetchArticlesAndGeoJSON, mapCenter, mapZoom]);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      if (state.loading) {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }, 1000000);
  
    return () => clearTimeout(loadingTimeout);
  }, [state.loading, dispatch]);

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleCountryFilter = useCallback((country) => {
    if (COUNTRY_COORDINATES[country]) {
      dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
    }
  }, [dispatch]);

  const resetCountryFilter = useCallback(() => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: 'in' });
  }, [dispatch]);

  const handleCategoryModal = useCallback(() => {
    setLocalState(prev => ({ ...prev, isCategoryModalOpen: !prev.isCategoryModalOpen }));
  }, []);

  const handleCategorySubmit = useCallback(({ categories, subCategories }) => {
    dispatch({ type: 'SET_SELECTED_CATEGORIES', payload: categories });
    dispatch({ type: 'SET_SELECTED_SUB_CATEGORIES', payload: subCategories });
  }, [dispatch]);

  if (state.loading) {
    return <div className="loading-container">Loading news data...</div>;
  }

  if (localState.error) {
    return <div className="error-container">{localState.error}</div>;
  }

  if (!state.articles || state.articles.length === 0) {
    return <div className="loading-container">No news data available.</div>;
  }

<ArticlesDebugger state={state} filteredArticles={filteredArticles} />

  return (
    <div className="app-container">
      {localState.showLocationBanner && localState.isLocationFetched && (
        <div className="location-banner" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: '#FEF3C7',
          color: '#721c24',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1004
        }}>
          <span>Unable to fetch user location. Please turn on the device location.</span>
          <button 
            onClick={handleCloseErrorBanner}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px'
            }}
          >
            <X size={24} />
          </button>
        </div>
      )}
      <FilterByCountry 
        onCountrySelect={handleCountryFilter}
        selectedCountry={state.selectedCountry}
        onReset={resetCountryFilter}
        availableCountries={availableCountries}
        onCategoryClick={handleCategoryModal}
        onViewToggle={handleViewToggle}
      />
      <div className={`map-sidebar ${isMobile ? 'mobile-view' : ''}`} data-view={activeView}>
      {isMobile ? (
        <>
          {activeView === 'map' ? (
            <div className="map-container-mobile">
              <MapContainer
                center={COUNTRY_COORDINATES['in'].center}
                zoom={COUNTRY_COORDINATES['in'].zoom}
                style={{ height: '100%', width: '100%' }}
                ref={mapRef}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapEvents />
                <MapContent />
                {state.userLocation && <SetViewOnUser coords={state.userLocation} />}
              </MapContainer>
            </div>
          ) : (
            <div className="sidebar-mobile">
              <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
                <SideBar
                  articles={state.groupedArticles || state.filteredArticles || []}
                  selectedArticle={state.selectedArticle?.id}
                  onArticleClick={handleMarkerClick}
                  currentDate={state.availableDates[state.currentDateIndex]}
                  onDateChange={handleDateChange}
                  isFirstDate={state.currentDateIndex === 0}
                  isLastDate={state.currentDateIndex === state.availableDates.length - 1}
                  currentDateString={(state.availableDates[state.currentDateIndex])}
                />
              </Suspense>
            </div>
          )}
        </>
      ): (
        // Desktop View - Regular Layout
        <>
          <div className="map-container" role="application" aria-label="Interactive news map">
            <MapContainer
              center={COUNTRY_COORDINATES['in'].center}
              zoom={COUNTRY_COORDINATES['in'].zoom}
              style={{ height: '100%', width: '100%' }}
              ref={mapRef}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <MapEvents />
              <MapContent />
              {state.userLocation && <SetViewOnUser coords={state.userLocation} />}
            </MapContainer>
          </div>
          <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
            <SideBar
              articles={state.groupedArticles || state.filteredArticles || []}
              selectedArticle={state.selectedArticle?.id}
              onArticleClick={handleMarkerClick}
              currentDate={state.availableDates[state.currentDateIndex]}
              onDateChange={handleDateChange}
              isFirstDate={state.currentDateIndex === 0}
              isLastDate={state.currentDateIndex === state.availableDates.length - 1}
              currentDateString={(state.availableDates[state.currentDateIndex])}
            />
          </Suspense>
        </>
      )}
        <Suspense fallback={<div role="status" aria-live="polite">Loading dialog...</div>}>
          <Dialog articles={state.articles}/>
        </Suspense>
        {localState.isCategoryModalOpen && (
          <CategoryFilterModal 
            onClose={() => setLocalState(prev => ({ ...prev, isCategoryModalOpen: false }))}
            onSubmit={handleCategorySubmit}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(MapComponent);