import React, { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { MenuSquare, MapPin } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import MobileMenu from '../MobileMenu/MobileMenu';
import './FilterByCountry.css'

const COUNTRY_COORDINATES  = {
  'ar': { name: 'Argentina', coordinates: [-38.416097, -63.616672], zoom: 4 },
  'au': { name: 'Australia', coordinates: [-25.274398, 133.775136], zoom: 4 },
  'at': { name: 'Austria', coordinates: [47.516231, 14.550072], zoom: 7 },
  'be': { name: 'Belgium', coordinates: [50.503887, 4.469936], zoom: 7 },
  'br': { name: 'Brazil', coordinates: [-14.235004, -51.925280], zoom: 4 },
  'bg': { name: 'Bulgaria', coordinates: [42.733883, 25.485830], zoom: 7 },
  'ca': { name: 'Canada', coordinates: [56.130366, -106.346771], zoom: 3 },
  'cn': { name: 'China', coordinates: [35.861660, 104.195397], zoom: 4 },
  'co': { name: 'Colombia', coordinates: [4.570868, -74.297333], zoom: 6 },
  'cz': { name: 'Czech Republic', coordinates: [49.817492, 15.472962], zoom: 7 },
  'eg': { name: 'Egypt', coordinates: [26.820553, 30.802498], zoom: 6 },
  'fr': { name: 'France', coordinates: [46.227638, 2.213749], zoom: 6 },
  'de': { name: 'Germany', coordinates: [51.165691, 10.451526], zoom: 6 },
  'gr': { name: 'Greece', coordinates: [39.074208, 21.824312], zoom: 7 },
  'hk': { name: 'Hong Kong', coordinates: [22.396428, 114.109497], zoom: 10 },
  'hu': { name: 'Hungary', coordinates: [47.162494, 19.503304], zoom: 7 },
  'in': { name: 'India', coordinates: [20.593684, 78.96288], zoom: 5 },
  'id': { name: 'Indonesia', coordinates: [-0.789275, 113.921327], zoom: 5 },
  'ir': { name: 'Ireland', coordinates: [53.412910, -8.243890], zoom: 7 },
  'il': { name: 'Israel', coordinates: [31.046051, 34.851612], zoom: 7 },
  'it': { name: 'Italy', coordinates: [41.871940, 12.567380], zoom: 6 },
  'jp': { name: 'Japan', coordinates: [36.204824, 138.252924], zoom: 5 },
  'lv': { name: 'Latvia', coordinates: [56.879635, 24.603189], zoom: 7 },
  'lt': { name: 'Lithuania', coordinates: [55.169438, 23.881275], zoom: 7 },
  'my': { name: 'Malaysia', coordinates: [4.210484, 101.975766], zoom: 6 },
  'mx': { name: 'Mexico', coordinates: [23.634501, -102.552784], zoom: 5 },
  'ma': { name: 'Morocco', coordinates: [31.791702, -7.092620], zoom: 6 },
  'nl': { name: 'Netherlands', coordinates: [52.132633, 5.291266], zoom: 7 },
  'nz': { name: 'New Zealand', coordinates: [-40.900557, 174.885971], zoom: 5 },
  'ng': { name: 'Nigeria', coordinates: [9.081999, 8.675277], zoom: 6 },
  'no': { name: 'Norway', coordinates: [60.472024, 8.468946], zoom: 5 },
  'ph': { name: 'Philippines', coordinates: [12.879721, 121.774017], zoom: 6 },
  'pl': { name: 'Poland', coordinates: [51.919438, 19.145136], zoom: 6 },
  'pt': { name: 'Portugal', coordinates: [39.399872, -8.224454], zoom: 7 },
  'ro': { name: 'Romania', coordinates: [45.943161, 24.966760], zoom: 7 },
  'sa': { name: 'Saudi Arabia', coordinates: [23.885942, 45.079162], zoom: 5 },
  'rs': { name: 'Serbia', coordinates: [44.016521, 21.005859], zoom: 7 },
  'sg': { name: 'Singapore', coordinates: [1.352083, 103.819836], zoom: 11 },
  'sk': { name: 'Slovakia', coordinates: [48.669026, 19.699024], zoom: 7 },
  'si': { name: 'Slovenia', coordinates: [46.151241, 14.995463], zoom: 8 },
  'za': { name: 'South Africa', coordinates: [-30.559482, 22.937506], zoom: 5 },
  'kr': { name: 'South Korea', coordinates: [35.907757, 127.766922], zoom: 7 },
  'se': { name: 'Sweden', coordinates: [60.128161, 18.643501], zoom: 5 },
  'ch': { name: 'Switzerland', coordinates: [46.818188, 8.227512], zoom: 8 },
  'tw': { name: 'Taiwan', coordinates: [23.697810, 120.960515], zoom: 7 },
  'th': { name: 'Thailand', coordinates: [15.870032, 100.992541], zoom: 6 },
  'tr': { name: 'Turkey', coordinates: [38.963745, 35.243322], zoom: 6 },
  'ae': { name: 'UAE', coordinates: [23.424076, 53.847818], zoom: 7 },
  'ua': { name: 'Ukraine', coordinates: [48.379433, 31.165581], zoom: 6 },
  'gb': { name: 'United Kingdom', coordinates: [55.378051, -3.435973], zoom: 5 },
  'us': { name: 'United States', coordinates: [37.090240, -95.712891], zoom: 4 },
  've': { name: 'Venezuela', coordinates: [6.423750, -66.589730], zoom: 6 }
};

const MobileViewToggle = ({ onMapToggle, onSidebarToggle, activeView }) => {
  return (
    <div className="mobile-view-toggle">
      <button 
        onClick={onMapToggle} 
        className={`view-toggle-button ${activeView === 'map' ? 'active' : ''}`}
      >
        <MapPin size={24} strokeWidth={1.5} />
      </button>
      <button 
        onClick={onSidebarToggle} 
        className={`view-toggle-button ${activeView === 'sidebar' ? 'active' : ''}`}
      >
        <MenuSquare size={24} strokeWidth={1.5} />
      </button>
    </div>
  );
};

const FilterByCountry = ({onCategoryClick,onViewToggle  }) => {
  const { state, dispatch } = useAppContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeView, setActiveView] = useState('map');

  const availableCountries = useMemo(() => {
    // If no countries available, use COUNTRY_COORDINATES
    const countriesToUse = state.availableCountries

    return countriesToUse.reduce((acc, countryCode) => {
      const normalizedCode = countryCode.toLowerCase();
      const countryInfo = COUNTRY_COORDINATES[normalizedCode];
      
      acc[normalizedCode] = {
        code: normalizedCode,
        name: countryInfo?.name || normalizedCode.toUpperCase(),
        ...countryInfo
      };
      
      return acc;
    }, {});
  }, [state.availableCountries]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewToggle = (view) => {
    setActiveView(view);
    console.log(view);
    onViewToggle(view);
  };

  const handleClick = (country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
  };

  const getButtonClass = (countryCode) => {
    if (state.selectedCountry === countryCode) return 'active';
  };
  

  const filteredCountries = Object.values(availableCountries).filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const today = format(new Date(), 'MMMM d, yyyy');

  const DesktopView = () => (
    <div className='desktop-view'>
      <div className="country-buttons">
        <img src='mmn4 1.png' alt="Logo" className='logo' />
        <div className="country-container">
        {filteredCountries.map((country) => (
            <button
              key={country.code}
              onClick={() => handleClick(country.code)}
              className={`country-button ${getButtonClass(country.code)}`}
            >
              {country.name}
            </button>
          ))}
        </div>
      </div>
      <div className="date-display">
        <p className='date-display-p'>{today}</p>
        <button className="category-desktop-filter-button" onClick={onCategoryClick}>
          {'Select Category'}
        </button>
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="mobile-view">
      <img src='mmn4 1.png' alt="Logo" className='logo' />
      {/* <p className="mobile-date">{today}</p> */}
      <MobileViewToggle 
          onMapToggle={() => handleViewToggle('map')}
          onSidebarToggle={() => handleViewToggle('sidebar')}
          activeView={activeView}
        />
      <MobileMenu 
      onCategoryClick={onCategoryClick}
      availableCountries={availableCountries}
    />
    </div>
  );

  return (
    <div className='filter-container'>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  );
};

export default FilterByCountry;